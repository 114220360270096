import LandscapeCards from "../components/landscape-cards";

export default function Packaging () {
    return (
        <div className="no-sidebar">
            <LandscapeCards/>
            <LandscapeCards/>
            <LandscapeCards/>
            <LandscapeCards/>
         
        </div>
    )
}