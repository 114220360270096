import PortraitCards from "../components/cards"

export default function Manufacturing () {
    return (
        <div className="no-sidebar">
            <PortraitCards/>
            <PortraitCards/>
            <PortraitCards/>
            <PortraitCards/>
        </div>
    )
}